
html, body {
  margin:0;
  overflow-x:hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import 'bulma/css/bulma.css';
@import "~react-image-gallery/styles/css/image-gallery.css";

.scrolling-wrapper {
  overflow-x: scroll;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}


.anton-regular {
  font-family: "Anton", sans-serif;
  font-weight: 400;
  font-style: normal;
}
:-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
@keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-text {
  animation: slideRight 2s ease-in-out backwards;
}

@keyframes slideLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-text2 {
  animation: slideLeft 3s ease-in-out forwards;
}

@keyframes slideRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}



@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear3 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.appear-text {
  animation: appear 3s ease-in-out forwards;
}

.appear-text2 {
  animation: appear 5s ease-in-out forwards;
}
.appear-text3 {
  animation: appear 5s ease-in-out forwards;
}

.appear-text4 {
  animation: appear 2s ease-in ;
}

.appear-text5 {
  animation: appear 3s ease-in ;
}

.appear-text6 {
  animation: appear 4s ease-in ;
}
.appear-text7 {
  animation: appear 5s ease-in;
}

 .avatar {
  border-radius: 50%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}



#rcorners {
  border-radius: 250px;
}

.fade-in-3 {
  animation: fadeIn ease 3s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}


.fade-in-2 {
  animation: fadeIn ease 6s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}


.fade-in-1 {
  animation: fadeIn ease 8s;
}
@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

.fade-in-4 {
  animation: fadeIn4 ease 12s;
}
@keyframes fadeIn4 {
  0% {
    opacity:.3;
  }
  100% {
    opacity:1;
  }
}

.avatar {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.75) inset;
  -moz-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.75) inset;
}



.centered-text {
  text-align: center;
}

.overlay {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: hsl(0, 0%, 7%, .8); 
}


.column {
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  display: flex;
}

.main_bkg_vid {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.background-image {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  overflow: hidden; 
}

/* unvisited link */
a:link {
  color: black;
}

/* visited link */
a:visited {
  color: blue;
}

/* mouse over link */
a:hover {
  color: hsl(171, 100%, 41%)	!important; 
}

/* selected link */
a:active {
  color: blue;
}

.navbar-item {
  color: #ffffff ; 
  transition: color 0.3s ease; 
}

.navbar-item:hover {
  color: #000000 ; 
}

.navbar-item2 {
  color: black!important; 
  transition: color 0.3s ease; 
}

.navbar-item2:hover {
  color: blue!important; 
}

.navbar-item3 {
  color: white!important; 
  transition: color 0.3s ease; 
}

.navbar-item3:hover {
  color: hsl(171, 100%, 41%)	!important; 
}
.navbar-item4 {
  color: black!important; 
  transition: color 0.3s ease; 
}

.navbar-item4:hover {
  color: hsl(171, 100%, 41%)	!important; 
}

.navbar-item4:active {
  color: white!important; 
}

.hero {
  background: none; 
}

.hero-body {
  color: white;
}

.title, .subtitle {
  position: relative;
  z-index: 3; 
}

.level {
  display: flex;
  justify-content: center; /* Center items horizontally */
}

.level-item {
  margin: 0 15px; /* Optional: Add spacing between items */
}

.heading {
  font-weight: bold;
}
.background-paragraph {
  background-color: black;
  padding: 20px; 
  color: white;
  font-size: 1rem;
  opacity: .8;
  border-radius: 10px;

}

.hero-body2 {
  width: 47%; /* Width for desktop */
  margin-left: 0; /* Aligns the .hero-body to the left */
  margin-right: auto; /* Centers the .hero-body within its container */
  /* Ensure it's properly aligned to the left on desktop */
}

.background-paragraph2 {
  max-width: 100%; /* Ensure the paragraph doesn’t exceed its container */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .hero-body2 {
    width: 100%; /* Full width for mobile */
    margin-left: 0; /* Full width on mobile means no need for left margin adjustment */
    margin-right: 0; /* Remove any right margin */
    padding: 0 5%; /* Optional: Add some padding for better spacing on mobile */
  }
}

.content-wrapper {
  width: 100%;
  max-width: 800px; /* Maximum width for larger screens */
}

@media (max-width: 768px) {
  .content-wrapper {
    width: 100%; /* Full width on mobile devices */
    padding: 0 10px; /* Adjust padding for mobile */
  }
}